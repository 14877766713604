import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ServiceCard = ({ service, index }) => {
  return (
    <motion.div 
      className="hover-card relative rounded-2xl overflow-hidden bg-gray-900/50 backdrop-blur-sm border border-white/5"
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-50px" }}
      transition={{ duration: 0.6, delay: index * 0.1 }}
      whileHover={{ y: -8, boxShadow: "0 20px 40px rgba(0,0,0,0.2)" }}
    >
      {/* Service card gradient background */}
      <div className={`absolute inset-0 bg-gradient-to-br ${service.color} opacity-5`}></div>
      
      <div className="relative p-6 md:p-8 h-full flex flex-col">
        {/* Service icon */}
        <div className="service-icon-container mb-6">
          <div className={`service-icon w-14 h-14 rounded-2xl flex items-center justify-center text-2xl bg-gradient-to-br ${service.color} shadow-lg`}>
            {service.icon}
          </div>
        </div>
        
        {/* Service title */}
        <h3 className="text-xl md:text-2xl font-bold mb-4 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
          {service.title}
        </h3>
        
        {/* Service description */}
        <p className="text-gray-400 mb-6 flex-grow">
          {service.description}
        </p>
        
        {/* Technologies */}
        <div className="flex flex-wrap gap-2 mb-4">
          {service.technologies.map((tech, idx) => (
            <span 
              key={idx} 
              className={`px-3 py-1 rounded-full text-xs font-medium bg-white/5 border border-white/10 text-gray-300`}
            >
              {tech}
            </span>
          ))}
        </div>
        
        {/* Learn more link */}
        <motion.a 
          href="#" 
          className={`group inline-flex items-center text-sm font-medium bg-gradient-to-r ${service.color} bg-clip-text text-transparent`}
          whileHover={{ x: 5 }}
          transition={{ type: 'spring', stiffness: 400, damping: 10 }}
        >
          Learn more
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className={`ml-1 transition-transform duration-300 group-hover:translate-x-1 bg-gradient-to-r ${service.color} bg-clip-text text-transparent`}
          >
            <path d="M5 12h14"></path>
            <path d="m12 5 7 7-7 7"></path>
          </svg>
        </motion.a>
      </div>
    </motion.div>
  );
};

const Services = () => {
  const sectionRef = useRef(null);

  const services = [
    {
      icon: '💻',
      title: 'Web Development',
      description: 'Stunning responsive websites and progressive web apps built with cutting-edge technologies like React, Vue, and Angular.',
      technologies: ['React', 'Vue.js', 'Angular', 'Node.js', 'PHP'],
      color: 'from-primary to-indigo-600'
    },
    {
      icon: '📱',
      title: 'Mobile Apps',
      description: 'Native and cross-platform applications for iOS and Android that deliver exceptional user experiences and performance.',
      technologies: ['React Native', 'Flutter', 'Swift', 'Kotlin'],
      color: 'from-blue-500 to-secondary'
    },
    {
      icon: '☁️',
      title: 'Cloud Solutions',
      description: 'Scalable, secure, and reliable cloud infrastructure and services to power your digital business needs.',
      technologies: ['AWS', 'Azure', 'Google Cloud', 'Docker', 'Kubernetes'],
      color: 'from-cyan-500 to-teal-400'
    },
    {
      icon: '🤖',
      title: 'AI Integration',
      description: 'Implement cutting-edge AI technologies to automate processes, analyze data, and create intelligent applications.',
      technologies: ['Machine Learning', 'NLP', 'Computer Vision', 'Chatbots'],
      color: 'from-teal-400 to-green-500'
    },
    {
      icon: '🛒',
      title: 'E-Commerce',
      description: 'Complete online store solutions with secure payment gateways, inventory management, and customer analytics.',
      technologies: ['Shopify', 'WooCommerce', 'Magento', 'Custom Solutions'],
      color: 'from-green-500 to-yellow-400'
    },
    {
      icon: '🔒',
      title: 'Cybersecurity',
      description: 'Protect your digital assets with comprehensive security assessments, implementation, and monitoring.',
      technologies: ['Penetration Testing', 'Security Audits', 'Authentication', 'Encryption'],
      color: 'from-yellow-400 to-orange-500'
    }
  ];

  useEffect(() => {
    // Parallax background effect
    gsap.to('.services-bg-gradient', {
      y: '-20%',
      ease: 'none',
      scrollTrigger: {
        trigger: sectionRef.current,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 0.5
      }
    });
    
    // Clean up
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <section
      id="services"
      ref={sectionRef}
      className="relative min-h-screen py-20 md:py-32 overflow-hidden bg-gray-950"
    >
      {/* Background elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Gradient background that moves on scroll */}
        <div className="services-bg-gradient absolute top-[-50%] left-0 right-0 bottom-0 bg-gradient-to-b from-black to-gray-900"></div>
        
        {/* Grid pattern */}
        <div className="absolute inset-0 bg-grid-pattern opacity-20"></div>
        
        {/* Gradient orbs */}
        <div className="absolute top-20 left-[10%] w-[300px] h-[300px] rounded-full bg-gradient-to-r from-primary/5 to-transparent blur-[80px] animate-pulse-slow"></div>
        <div className="absolute bottom-40 right-[5%] w-[250px] h-[250px] rounded-full bg-gradient-to-r from-secondary/5 to-transparent blur-[60px] animate-pulse-slow animation-delay-1000"></div>
      </div>
      
      <div className="relative z-10 container mx-auto px-4 md:px-8">
        {/* Section header */}
        <div className="text-center mb-16 md:mb-24">
          <motion.p 
            className="text-secondary font-medium mb-2"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            OUR EXPERTISE
          </motion.p>
          
          <motion.h2 
            className="text-3xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            Services We Provide
          </motion.h2>
          
          <motion.div 
            className="mx-auto h-1 w-20 bg-gradient-to-r from-primary to-secondary rounded-full mb-6"
            initial={{ opacity: 0, scaleX: 0 }}
            whileInView={{ opacity: 1, scaleX: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7, delay: 0.2 }}
          ></motion.div>
          
          <motion.p 
            className="text-gray-400 max-w-2xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            We deliver exceptional digital solutions tailored to your unique business needs,
            combining technical expertise with creative innovation.
          </motion.p>
        </div>
        
        {/* Services grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} index={index} />
          ))}
        </div>
        
        {/* CTA section */}
        <motion.div 
          className="mt-20 md:mt-32 text-center"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h3 className="text-2xl md:text-3xl font-bold mb-6 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Need a custom solution?
          </h3>
          
          <p className="text-gray-400 max-w-xl mx-auto mb-8">
            We're here to help you achieve your digital goals with tailored solutions
            designed specifically for your business requirements.
          </p>
          
          <motion.button 
            className="px-8 py-4 bg-gradient-to-r from-primary to-secondary text-white rounded-full font-medium shadow-lg shadow-primary/20 hover:shadow-xl hover:shadow-primary/30 transition-all duration-300"
            whileHover={{ scale: 1.05, y: -3 }}
            whileTap={{ scale: 0.95 }}
          >
            Get a Free Consultation
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default Services; 