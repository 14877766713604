import React from 'react';
import { motion } from 'framer-motion';

const Loader = () => {
  // Loader container variants
  const containerVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };
  
  // Logo text variants
  const textVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };
  
  // Loading bar variants
  const barVariants = {
    initial: { width: "0%" },
    animate: { 
      width: "100%",
      transition: { 
        duration: 2.5,
        ease: "easeInOut"
      }
    }
  };
  
  // Circle variants
  const circleVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: { 
      scale: [0, 1.2, 1],
      opacity: [0, 1, 1],
      transition: { 
        duration: 1.2,
        ease: "easeInOut",
        times: [0, 0.6, 1]
      }
    }
  };

  // Text animation
  const letterVariants = {
    initial: { opacity: 0, y: 20 },
    animate: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.05
      }
    })
  };

  const text = "INITIALIZING WEBCODERZ SYSTEM";
  
  return (
    <motion.div 
      className="fixed inset-0 bg-dark flex flex-col items-center justify-center z-50"
      variants={containerVariants}
      initial="initial"
      animate="animate"
    >
      <div className="grid-bg"></div>
      
      <motion.div 
        className="text-gradient text-5xl md:text-7xl font-bold mb-8"
        variants={textVariants}
      >
        WebCoderz
      </motion.div>
      
      <motion.div className="relative w-64 h-64 mb-8">
        <motion.div 
          className="absolute inset-0 border-4 border-primary rounded-full"
          variants={circleVariants}
        />
        <motion.div 
          className="absolute inset-0 border-4 border-dashed border-secondary rounded-full"
          animate={{ 
            rotate: 360
          }}
          transition={{ 
            duration: 8, 
            ease: "linear", 
            repeat: Infinity 
          }}
        />
        <motion.div 
          className="absolute inset-8 border-2 border-primary-light rounded-full"
          variants={circleVariants}
        />
        <motion.div 
          className="absolute inset-16 border-2 border-secondary-light rounded-full"
          variants={circleVariants}
          animate={{ 
            rotate: -360
          }}
          transition={{ 
            duration: 12, 
            ease: "linear", 
            repeat: Infinity 
          }}
        />
        <motion.div
          className="absolute inset-0 flex items-center justify-center"
        >
          <motion.div
            className="w-24 h-24 bg-gradient-to-br from-primary to-secondary rounded-full opacity-80"
            animate={{ 
              scale: [1, 1.2, 1], 
              opacity: [0.7, 1, 0.7] 
            }}
            transition={{ 
              duration: 3, 
              ease: "easeInOut", 
              repeat: Infinity 
            }}
          />
        </motion.div>
      </motion.div>
      
      <div className="mb-4">
        <motion.div 
          className="text-white text-lg mb-2 flex overflow-hidden"
          initial="initial"
          animate="animate"
        >
          {text.split('').map((letter, i) => (
            <motion.span
              key={i}
              custom={i}
              variants={letterVariants}
            >
              {letter === ' ' ? '\u00A0' : letter}
            </motion.span>
          ))}
        </motion.div>
      </div>
      
      <div className="w-80 h-2 bg-dark-light rounded-full overflow-hidden">
        <motion.div 
          className="h-full bg-gradient-to-r from-primary to-secondary"
          variants={barVariants}
        />
      </div>
    </motion.div>
  );
};

export default Loader; 