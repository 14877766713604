import React, { useState } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedProject, setSelectedProject] = useState(null);
  
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });
  
  const controls = useAnimation();
  
  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  
  const projects = [
    {
      id: 1,
      title: 'E-Commerce Platform',
      category: 'web',
      image: 'https://via.placeholder.com/600x400/6d28d9/ffffff?text=E-Commerce+Platform',
      description: 'A complete e-commerce solution with advanced product filtering, secure payments, and customer dashboard.',
      technologies: ['React', 'Node.js', 'MongoDB', 'Stripe'],
      link: '#'
    },
    {
      id: 2,
      title: 'Health & Fitness App',
      category: 'mobile',
      image: 'https://via.placeholder.com/600x400/8b5cf6/ffffff?text=Fitness+App',
      description: 'Mobile application for fitness tracking, workout planning, and nutrition management with social features.',
      technologies: ['React Native', 'Firebase', 'Redux', 'HealthKit'],
      link: '#'
    },
    {
      id: 3,
      title: 'AI-Powered Analytics Dashboard',
      category: 'ai',
      image: 'https://via.placeholder.com/600x400/06b6d4/ffffff?text=AI+Analytics',
      description: 'Business intelligence platform with predictive analytics and machine learning-driven insights.',
      technologies: ['Python', 'TensorFlow', 'Next.js', 'D3.js'],
      link: '#'
    },
    {
      id: 4,
      title: 'Cloud Management Solution',
      category: 'cloud',
      image: 'https://via.placeholder.com/600x400/0891b2/ffffff?text=Cloud+Management',
      description: 'Comprehensive cloud infrastructure management tool for multi-cloud environments.',
      technologies: ['AWS', 'Docker', 'Kubernetes', 'Go'],
      link: '#'
    },
    {
      id: 5,
      title: 'Social Media Platform',
      category: 'web',
      image: 'https://via.placeholder.com/600x400/5b21b6/ffffff?text=Social+Media',
      description: 'Feature-rich social networking platform with real-time messaging and content sharing capabilities.',
      technologies: ['Vue.js', 'GraphQL', 'PostgreSQL', 'WebSockets'],
      link: '#'
    },
    {
      id: 6,
      title: 'Augmented Reality Catalog',
      category: 'mobile',
      image: 'https://via.placeholder.com/600x400/22d3ee/ffffff?text=AR+Catalog',
      description: 'AR-powered product catalog app allowing users to visualize products in their real environment.',
      technologies: ['ARKit', 'Swift', 'SceneKit', 'Core ML'],
      link: '#'
    }
  ];
  
  const categories = [
    { id: 'all', name: 'All Projects' },
    { id: 'web', name: 'Web Development' },
    { id: 'mobile', name: 'Mobile Apps' },
    { id: 'cloud', name: 'Cloud Solutions' },
    { id: 'ai', name: 'AI Integration' }
  ];
  
  const filteredProjects = selectedCategory === 'all' 
    ? projects 
    : projects.filter(project => project.category === selectedCategory);
  
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };
  
  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: 'easeOut'
      }
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.3,
        ease: 'easeIn'
      }
    }
  };

  return (
    <div className="container-padding py-20 relative" ref={ref}>
      <div className="grid-bg"></div>
      
      {/* Background gradients */}
      <div className="absolute top-40 right-20 w-72 h-72 rounded-full bg-primary/20 mix-blend-screen blur-3xl"></div>
      <div className="absolute bottom-40 left-20 w-80 h-80 rounded-full bg-secondary/20 mix-blend-screen blur-3xl"></div>
      
      <div className="max-w-7xl mx-auto">
        {/* Section heading */}
        <div className="text-center mb-16">
          <motion.h2 
            className="section-title"
            initial={{ opacity: 0, y: -20 }}
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
            }}
          >
            Our Portfolio
          </motion.h2>
          <motion.p 
            className="text-lg text-white/70 max-w-2xl mx-auto"
            initial={{ opacity: 0 }}
            animate={controls}
            variants={{
              visible: { opacity: 1, transition: { delay: 0.2 } }
            }}
          >
            Explore our latest projects and discover how we've helped businesses achieve their digital goals
          </motion.p>
        </div>
        
        {/* Category filter */}
        <motion.div 
          className="flex flex-wrap justify-center gap-3 mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          variants={{
            visible: { 
              opacity: 1, 
              y: 0,
              transition: { delay: 0.3 }
            }
          }}
        >
          {categories.map(category => (
            <motion.button
              key={category.id}
              className={`px-5 py-2 rounded-full font-medium transition-all duration-300 ${
                selectedCategory === category.id
                  ? 'bg-primary text-white shadow-lg'
                  : 'bg-dark-light text-white/70 hover:bg-primary/20'
              }`}
              onClick={() => setSelectedCategory(category.id)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {category.name}
            </motion.button>
          ))}
        </motion.div>
        
        {/* Projects grid */}
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          variants={containerVariants}
          initial="hidden"
          animate={controls}
        >
          <AnimatePresence>
            {filteredProjects.map(project => (
              <motion.div
                key={project.id}
                layout
                variants={itemVariants}
                className="glass rounded-xl overflow-hidden"
                whileHover={{ 
                  y: -10,
                  boxShadow: '0 20px 25px -5px rgba(109, 40, 217, 0.3)'
                }}
                transition={{ duration: 0.3 }}
                onClick={() => setSelectedProject(project)}
              >
                <div className="relative overflow-hidden h-52">
                  <img 
                    src={project.image} 
                    alt={project.title}
                    className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-dark to-transparent opacity-70"></div>
                  <div className="absolute bottom-0 left-0 p-4 w-full">
                    <h3 className="text-xl font-bold text-white">{project.title}</h3>
                  </div>
                </div>
                <div className="p-4">
                  <div className="flex flex-wrap gap-2 mb-3">
                    {project.technologies.slice(0, 3).map((tech, i) => (
                      <span key={i} className="text-xs px-2 py-1 bg-primary/20 rounded-full text-white/90">
                        {tech}
                      </span>
                    ))}
                    {project.technologies.length > 3 && (
                      <span className="text-xs px-2 py-1 bg-secondary/20 rounded-full text-white/90">
                        +{project.technologies.length - 3} more
                      </span>
                    )}
                  </div>
                  <p className="text-white/70 text-sm line-clamp-2">{project.description}</p>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
        
        {/* View more button */}
        <motion.div 
          className="text-center mt-12"
          initial={{ opacity: 0 }}
          animate={controls}
          variants={{
            visible: { 
              opacity: 1,
              transition: { delay: 0.6 }
            }
          }}
        >
          <motion.button
            className="btn-primary"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            View All Projects
          </motion.button>
        </motion.div>
      </div>
      
      {/* Project modal */}
      <AnimatePresence>
        {selectedProject && (
          <motion.div
            className="fixed inset-0 bg-dark/80 backdrop-blur-md z-50 flex items-center justify-center p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedProject(null)}
          >
            <motion.div
              className="relative bg-dark-light rounded-xl max-w-4xl w-full max-h-[90vh] overflow-auto"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              onClick={e => e.stopPropagation()}
            >
              <button 
                className="absolute top-4 right-4 w-10 h-10 rounded-full bg-primary/20 flex items-center justify-center z-10"
                onClick={() => setSelectedProject(null)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              
              <div className="h-72 overflow-hidden">
                <img
                  src={selectedProject.image}
                  alt={selectedProject.title}
                  className="w-full h-full object-cover"
                />
              </div>
              
              <div className="p-6">
                <h3 className="text-3xl font-bold mb-4 text-gradient">{selectedProject.title}</h3>
                <p className="text-white/80 mb-6">{selectedProject.description}</p>
                
                <div className="mb-6">
                  <h4 className="text-lg font-semibold mb-2 text-white">Technologies Used</h4>
                  <div className="flex flex-wrap gap-2">
                    {selectedProject.technologies.map((tech, i) => (
                      <span key={i} className="px-3 py-1 bg-primary/20 rounded-full text-white/90">
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
                
                <div className="flex gap-4">
                  <a 
                    href={selectedProject.link} 
                    className="btn-primary flex-1 text-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Project
                  </a>
                  <button
                    className="flex-1 bg-transparent hover:bg-white/10 text-white font-bold py-3 px-6 rounded-full border border-white/30 transition-all duration-300"
                    onClick={() => setSelectedProject(null)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Portfolio; 