import React, { useRef, useEffect, useState, useMemo } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion } from 'framer-motion';

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
  const sectionRef = useRef(null);
  const headingRef = useRef(null);
  const contentRef = useRef(null);
  const timelineRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const projects = useMemo(() => [
    {
      title: "Digital Experiences",
      description: "Creating memorable digital journeys through thoughtful design and seamless interaction.",
      tags: ["Web", "UX/UI", "Motion"],
      color: "#6366f1"
    },
    {
      title: "Mobile Innovation",
      description: "Building native applications that transform how users connect with your brand.",
      tags: ["iOS", "Android", "React Native"],
      color: "#0ea5e9"
    },
    {
      title: "Custom Solutions",
      description: "Developing tailored technology solutions that solve your unique business challenges.",
      tags: ["API", "Integration", "Architecture"],
      color: "#10b981" 
    },
    {
      title: "AI & Machine Learning",
      description: "Leveraging cutting-edge technology to create intelligent, adaptive systems.",
      tags: ["ML", "Data", "Automation"],
      color: "#f43f5e"
    }
  ], []);
  
  useEffect(() => {
    // Create main timeline
    timelineRef.current = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top top",
        end: "bottom bottom",
        scrub: 1,
        pin: true,
        pinSpacing: true,
      }
    });
    
    // Animate the heading characters
    const headingText = headingRef.current;
    if (headingText) {
      // Split text into spans for each character
      const chars = headingText.textContent.split('');
      headingText.textContent = '';
      
      chars.forEach(char => {
        const span = document.createElement('span');
        span.textContent = char === ' ' ? '\u00A0' : char;
        span.className = 'inline-block transition-transform duration-300 char-element';
        headingText.appendChild(span);
      });
      
      // Animate each character
      const charElements = headingText.querySelectorAll('.char-element');
      timelineRef.current.from(charElements, {
        opacity: 0.1,
        scale: 0.8,
        y: gsap.utils.wrap([-40, 40]),
        stagger: 0.03,
        duration: 0.8,
        ease: "power3.out"
      }, 0);
    }
    
    // Progress tracker animation
    const progressTrack = document.querySelector('.progress-track');
    if (progressTrack) {
      timelineRef.current.to(progressTrack, {
        width: '100%',
        duration: 1,
        ease: "none"
      }, 0);
    }
    
    // Clean up
    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill();
      }
      const scrollTriggers = ScrollTrigger.getAll();
      scrollTriggers.forEach(trigger => trigger.kill());
    };
  }, []);
  
  // Handle automatic advancement and manual interaction
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prev => (prev + 1) % projects.length);
    }, 5000);
    
    return () => clearInterval(interval);
  }, [projects.length]);
  
  // Handle parallax effect on mouse move
  useEffect(() => {
    const content = contentRef.current;
    if (!content) return;
    
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const { left, top, width, height } = content.getBoundingClientRect();
      
      // Calculate normalized coordinates (-1 to 1)
      const x = (clientX - left) / width - 0.5;
      const y = (clientY - top) / height - 0.5;
      
      // Apply subtle parallax movement to different layers
      gsap.to('.layer-1', {
        x: x * 20,
        y: y * 20,
        duration: 0.5
      });
      
      gsap.to('.layer-2', {
        x: x * -30,
        y: y * -20,
        duration: 0.3
      });
      
      gsap.to('.layer-3', {
        x: x * 50,
        y: y * 30,
        duration: 0.8
      });
    };
    
    content.addEventListener('mousemove', handleMouseMove);
    
    return () => {
      content.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <section 
      ref={sectionRef}
      className="relative min-h-screen flex flex-col items-center justify-center overflow-hidden bg-black px-4 sm:px-6"
    >
      {/* Background elements - made more responsive */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-950 to-black overflow-hidden z-0">
        {/* Advanced grid pattern */}
        <div className="absolute inset-0 bg-grid-pattern opacity-20"></div>
        
        {/* Animated gradient spheres - adjusted for better mobile appearance */}
        <div className="absolute top-1/4 left-0 md:left-1/4 w-[280px] md:w-[500px] h-[280px] md:h-[500px] rounded-full bg-gradient-to-r from-primary/10 to-secondary/10 blur-[60px] md:blur-[80px] animate-pulse-slow transform-gpu"></div>
        <div className="absolute bottom-1/4 right-0 md:right-1/4 w-[240px] md:w-[400px] h-[240px] md:h-[400px] rounded-full bg-gradient-to-r from-secondary/10 to-primary/10 blur-[40px] md:blur-[60px] animate-pulse-slow animation-delay-2000 transform-gpu"></div>
        
        {/* Enhanced orbital layers with animation - adjusted for mobile */}
        <div className="absolute w-[150vw] h-[150vh] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 layer-1 animation-spin-slow opacity-50 md:opacity-100">
          <div className="absolute top-0 left-[5%] w-[70%] h-[70%] rounded-full border border-indigo-500/20 opacity-30"></div>
          <div className="absolute bottom-[15%] right-[10%] w-[55%] h-[55%] rounded-full border border-cyan-500/20 opacity-40"></div>
        </div>
        
        <div className="absolute w-[100vw] h-[100vh] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 layer-2 animation-spin-slow-reverse opacity-50 md:opacity-100">
          <div className="absolute w-[40%] h-[40%] top-[10%] right-[15%] rounded-full bg-gradient-to-tr from-indigo-900/10 to-transparent blur-3xl"></div>
          <div className="absolute w-[35%] h-[35%] bottom-[15%] left-[10%] rounded-full bg-gradient-to-br from-cyan-900/10 to-transparent blur-3xl"></div>
        </div>
        
        {/* Enhanced particles - adjusted visibility on mobile */}
        <div className="absolute w-[80vw] h-[80vh] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 layer-3 hidden sm:block">
          <div className="absolute w-1 h-1 top-[30%] left-[20%] rounded-full bg-primary/50 shadow-lg shadow-primary/20 animate-pulse"></div>
          <div className="absolute w-1.5 h-1.5 top-[45%] right-[30%] rounded-full bg-secondary/50 shadow-lg shadow-secondary/20 animate-pulse animation-delay-1000"></div>
          <div className="absolute w-2 h-2 bottom-[25%] left-[40%] rounded-full bg-indigo-500/50 shadow-lg shadow-indigo-500/20 animate-pulse animation-delay-1500"></div>
          <div className="absolute w-1 h-1 top-[20%] right-[25%] rounded-full bg-primary/50 shadow-lg shadow-primary/20 animate-pulse animation-delay-500"></div>
          <div className="absolute w-1.5 h-1.5 bottom-[35%] right-[15%] rounded-full bg-secondary/50 shadow-lg shadow-secondary/20 animate-pulse animation-delay-2000"></div>
        </div>
      </div>
      
      {/* Main content with refined layout - improved for mobile */}
      <div ref={contentRef} className="relative z-10 w-full max-w-7xl mx-auto py-12 md:py-0 md:h-screen flex flex-col justify-center">
        {/* Refined heading section */}
        <div className="w-full">
          <div className="flex flex-col md:flex-row items-center justify-between gap-8 md:gap-12">
            <div className="w-full md:w-1/2 space-y-5 md:space-y-6 text-center md:text-left">
              <div className="inline-block px-3 py-1 rounded-full bg-white/5 backdrop-blur-sm border border-white/10 mb-2 md:mb-4">
                <p className="text-xs md:text-sm font-medium text-gray-300">
                  <span className="inline-block w-2 h-2 rounded-full bg-green-400 mr-2 animate-pulse"></span>
                  Now Available for New Projects
                </p>
              </div>
              
              <h1 
                ref={headingRef}
                className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-extrabold text-white tracking-tight leading-tight"
              >
                We craft <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">digital</span> excellence
              </h1>
              
              <p className="text-base md:text-lg text-gray-400 max-w-xl mx-auto md:mx-0">
                Transforming ideas into exceptional digital experiences through thoughtful design and innovative technology.
              </p>
              
              <div className="flex flex-wrap justify-center md:justify-start gap-3 md:gap-4 pt-2 md:pt-4">
                <motion.button 
                  className="px-4 md:px-6 py-2.5 md:py-3 bg-gradient-to-r from-primary to-secondary text-white rounded-full font-medium flex items-center gap-2 shadow-lg shadow-primary/20 hover:shadow-xl hover:shadow-primary/30 transition-all duration-300 text-sm md:text-base"
                  whileHover={{ scale: 1.05, y: -2 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Get Started
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="ml-1">
                    <path d="M5 12h14"></path>
                    <path d="m12 5 7 7-7 7"></path>
                  </svg>
                </motion.button>
                
                <motion.button 
                  className="px-4 md:px-6 py-2.5 md:py-3 bg-white/5 backdrop-blur-sm text-white border border-white/10 rounded-full font-medium flex items-center gap-2 hover:bg-white/10 transition-all duration-300 text-sm md:text-base"
                  whileHover={{ scale: 1.05, y: -2 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Our Portfolio
                </motion.button>
              </div>
              
              {/* Trust indicators - show on mobile but more compact */}
              <div className="pt-8 md:pt-12">
                <p className="text-xs md:text-sm text-gray-500 mb-3 md:mb-4">Trusted by innovative companies</p>
                <div className="flex flex-wrap justify-center md:justify-start items-center gap-4 md:gap-6">
                  {/* iWebWala logo */}
                  <img src="https://iwebwala.com/assets/img/logo.webp" alt="iWebWala" className="h-5 md:h-7 opacity-50 grayscale hover:opacity-80 hover:grayscale-0 transition-all duration-300" />
                  
                  
                </div>
              </div>
            </div>
            
            {/* 3D visual element - smaller on mobile */}
            <div className="w-full md:w-1/2 flex justify-center mt-8 md:mt-0 md:py-0">
              <div className="relative w-60 h-60 sm:w-72 sm:h-72 md:w-80 md:h-80 lg:w-96 lg:h-96 perspective-container">
                <motion.div 
                  className="absolute inset-0 rounded-2xl bg-gradient-to-r from-primary/20 to-secondary/20 backdrop-blur-sm border border-white/10 shadow-xl transform-gpu"
                  animate={{ 
                    rotateX: [3, -3, 3],
                    rotateY: [3, -3, 3],
                  }}
                  transition={{
                    duration: 12,
                    repeat: Infinity,
                    repeatType: "mirror",
                    ease: "easeInOut"
                  }}
                >
                  <div className="absolute inset-6 rounded-lg bg-gradient-to-br from-primary/10 to-secondary/10 backdrop-blur-sm border border-white/5"></div>
                  
                  {/* Code snippet visual - simplified for mobile */}
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4/5 rounded-md bg-gray-900/90 p-3 md:p-4 shadow-xl">
                    <div className="flex items-center mb-2 md:mb-3">
                      <div className="w-2 h-2 md:w-3 md:h-3 rounded-full bg-red-500 mr-2"></div>
                      <div className="w-2 h-2 md:w-3 md:h-3 rounded-full bg-yellow-500 mr-2"></div>
                      <div className="w-2 h-2 md:w-3 md:h-3 rounded-full bg-green-500"></div>
                    </div>
                    <div className="space-y-1.5 md:space-y-2 font-mono text-[10px] md:text-xs text-gray-300">
                      <div><span className="text-pink-400">const</span> <span className="text-blue-400">webCoderz</span> <span className="text-pink-400">=</span> <span className="text-yellow-300">{`{`}</span></div>
                      <div className="pl-4"><span className="text-green-400">design:</span> <span className="text-orange-300">'exceptional'</span>,</div>
                      <div className="pl-4"><span className="text-green-400">code:</span> <span className="text-orange-300">'flawless'</span>,</div>
                      <div className="pl-4"><span className="text-green-400">experience:</span> <span className="text-orange-300">'immersive'</span></div>
                      <div><span className="text-yellow-300">{`}`}</span>;</div>
                      <div className="pt-1"><span className="text-pink-400">function</span> <span className="text-blue-400">createAmazing</span>() <span className="text-yellow-300">{`{`}</span></div>
                      <div className="pl-4"><span className="text-pink-400">return</span> <span className="text-blue-400">webCoderz</span>;</div>
                      <div><span className="text-yellow-300">{`}`}</span></div>
                    </div>
                  </div>
                  
                  {/* Decorative elements - adjusted for mobile */}
                  <div className="absolute top-4 right-4 md:top-6 md:right-6 w-6 h-6 md:w-10 md:h-10 rounded-full bg-primary/30 backdrop-blur-sm"></div>
                  <div className="absolute bottom-6 left-4 md:bottom-10 md:left-6 w-4 h-4 md:w-6 md:h-6 rounded-full bg-secondary/30 backdrop-blur-sm"></div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Scroll indicator - responsive positioning */}
        <div className="hidden md:flex absolute bottom-10 left-1/2 -translate-x-1/2 flex-col items-center">
          <p className="text-sm text-gray-500 mb-2">Scroll to explore</p>
          <motion.div 
            className="w-6 h-10 rounded-full border-2 border-gray-600 flex justify-center p-1"
            initial={{ opacity: 0.5, y: 0 }}
            animate={{ opacity: 1, y: [0, 5, 0] }}
            transition={{ duration: 1.5, repeat: Infinity }}
          >
            <motion.div 
              className="w-1.5 h-1.5 bg-white rounded-full"
              animate={{ y: [0, 10, 0] }}
              transition={{ duration: 1.5, repeat: Infinity }}
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero; 