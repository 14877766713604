import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = ({ scrollY }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('hero');

  useEffect(() => {
    setIsScrolled(scrollY > 50);

    // Update active section based on scroll position
    const sections = document.querySelectorAll('section[id]');
    let currentSection = 'hero';
    
    sections.forEach(section => {
      const sectionTop = section.offsetTop - 100;
      const sectionHeight = section.offsetHeight;
      
      if (scrollY >= sectionTop && scrollY < sectionTop + sectionHeight) {
        currentSection = section.getAttribute('id');
      }
    });
    
    setActiveLink(currentSection);
  }, [scrollY]);

  const navLinks = [
    { name: 'Home', href: '#hero' },
    { name: 'About', href: '#about' },
    { name: 'Services', href: '#services' },
    { name: 'Portfolio', href: '#portfolio' },
    { name: 'Contact', href: '#contact' },
  ];

  const navVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut'
      }
    }
  };

  const menuVariants = {
    closed: { 
      opacity: 0,
      scale: 0.95,
      y: -10,
      transition: { duration: 0.2 }
    },
    open: { 
      opacity: 1,
      scale: 1,
      y: 0,
      transition: { duration: 0.4, staggerChildren: 0.07, delayChildren: 0.1 }
    }
  };

  const linkVariants = {
    closed: { y: 20, opacity: 0 },
    open: { y: 0, opacity: 1 }
  };

  return (
    <motion.nav 
      className={`fixed top-0 w-full z-50 px-6 py-4 flex justify-between items-center transition-all duration-300 ${
        isScrolled ? 'glass backdrop-blur-lg shadow-lg' : 'bg-transparent'
      }`}
      initial="hidden"
      animate="visible"
      variants={navVariants}
    >
      {/* Logo */}
      <div className="flex items-center">
        <motion.div 
          className="text-3xl font-bold text-gradient"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
            WebCoderz
          </span>
        </motion.div>
      </div>

      {/* Desktop Menu */}
      <div className="hidden md:flex items-center space-x-8">
        {navLinks.map((link, index) => {
          const isActive = activeLink === link.href.substring(1);
          return (
            <motion.a
              key={index}
              href={link.href}
              className={`relative px-2 py-1 text-sm font-medium transition-colors duration-300 ${
                isActive ? 'text-white' : 'text-gray-300 hover:text-white'
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {link.name}
              {isActive && (
                <motion.div
                  className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-primary to-secondary rounded-full"
                  layoutId="activeIndicator"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                />
              )}
            </motion.a>
          );
        })}
        <motion.button
          className="btn-primary bg-gradient-to-r from-primary to-secondary-light text-white px-6 py-2 rounded-full text-sm font-medium transition-transform hover:shadow-lg hover:shadow-primary/20"
          whileHover={{ scale: 1.05, y: -2 }}
          whileTap={{ scale: 0.95 }}
        >
          Get Started
        </motion.button>
      </div>

      {/* Mobile Menu Toggle */}
      <div className="md:hidden">
        <motion.button 
          className="text-white focus:outline-none p-2 rounded-full hover:bg-white/5"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          whileTap={{ scale: 0.9 }}
        >
          <div className="w-6 h-5 relative flex flex-col justify-between">
            <motion.span 
              className="w-6 h-0.5 bg-white rounded-full block"
              animate={isMenuOpen ? { rotate: 45, y: 8 } : { rotate: 0, y: 0 }}
              transition={{ duration: 0.3 }}
            />
            <motion.span 
              className="w-6 h-0.5 bg-white rounded-full block"
              animate={isMenuOpen ? { opacity: 0 } : { opacity: 1 }}
              transition={{ duration: 0.3 }}
            />
            <motion.span 
              className="w-6 h-0.5 bg-white rounded-full block"
              animate={isMenuOpen ? { rotate: -45, y: -8 } : { rotate: 0, y: 0 }}
              transition={{ duration: 0.3 }}
            />
          </div>
        </motion.button>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div 
            className="md:hidden fixed top-[72px] left-0 right-0 p-4 glass backdrop-blur-xl m-4 rounded-2xl border border-white/10 shadow-xl"
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            <div className="flex flex-col space-y-2">
              {navLinks.map((link, index) => {
                const isActive = activeLink === link.href.substring(1);
                return (
                  <motion.a
                    key={index}
                    href={link.href}
                    className={`text-white font-medium px-4 py-3 rounded-xl transition-colors duration-300 flex items-center ${
                      isActive ? 'bg-white/10' : 'hover:bg-white/5'
                    }`}
                    variants={linkVariants}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <span 
                      className={`mr-3 w-1.5 h-1.5 rounded-full ${
                        isActive ? 'bg-gradient-to-r from-primary to-secondary' : 'bg-gray-500'
                      }`}
                    />
                    {link.name}
                  </motion.a>
                );
              })}
              <motion.div variants={linkVariants} className="pt-2">
                <motion.button
                  className="btn-primary w-full bg-gradient-to-r from-primary to-secondary-light text-white px-6 py-3 rounded-xl text-sm font-medium"
                  whileTap={{ scale: 0.95 }}
                >
                  Get Started
                </motion.button>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

export default Navbar; 