import React from 'react';
import { motion } from 'framer-motion';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const footerLinks = [
    {
      title: 'Company',
      links: [
        { name: 'About Us', href: '#about' },
        { name: 'Services', href: '#services' },
        { name: 'Portfolio', href: '#portfolio' },
        { name: 'Careers', href: '#careers' },
        { name: 'Contact', href: '#contact' }
      ]
    },
    {
      title: 'Services',
      links: [
        { name: 'Web Development', href: '#web' },
        { name: 'Mobile Apps', href: '#mobile' },
        { name: 'Cloud Solutions', href: '#cloud' },
        { name: 'AI Integration', href: '#ai' },
        { name: 'E-Commerce', href: '#ecommerce' }
      ]
    },
    {
      title: 'Resources',
      links: [
        { name: 'Blog', href: '#blog' },
        { name: 'Case Studies', href: '#case-studies' },
        { name: 'Documentation', href: '#docs' },
        { name: 'FAQ', href: '#faq' },
        { name: 'Privacy Policy', href: '#privacy' }
      ]
    }
  ];
  
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0
    }
  };

  return (
    <footer className="bg-dark-light relative">
      <div className="grid-bg opacity-20"></div>
      
      {/* Main Footer */}
      <div className="container-padding py-12 max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Brand Column */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-gradient mb-4">WebCoderz</h2>
            <p className="text-white/70 mb-6">
              Transforming ideas into cutting-edge digital experiences with innovative web, mobile, and cloud solutions.
            </p>
            <div className="flex space-x-3 mb-6">
              {['twitter', 'facebook', 'instagram', 'linkedin', 'github'].map((social, index) => (
                <motion.a
                  key={index}
                  href={`#${social}`}
                  className="w-9 h-9 rounded-full bg-dark flex items-center justify-center text-white/80 hover:bg-primary hover:text-white transition-colors duration-300"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <span className="sr-only">{social}</span>
                </motion.a>
              ))}
            </div>
            <div className="glass p-4 rounded-lg">
              <h3 className="text-white font-semibold mb-2">Subscribe to Our Newsletter</h3>
              <div className="flex">
                <input
                  type="email"
                  placeholder="Your email"
                  className="flex-1 bg-dark p-2 rounded-l-lg border-0 focus:outline-none text-white"
                />
                <button className="bg-primary hover:bg-primary-dark text-white px-4 py-2 rounded-r-lg transition-colors duration-300">
                  Subscribe
                </button>
              </div>
            </div>
          </motion.div>
          
          {/* Links Columns */}
          {footerLinks.map((column, index) => (
            <motion.div
              key={index}
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: 0.2 * (index + 1) }}
            >
              <h3 className="text-xl font-bold text-white mb-4">{column.title}</h3>
              <ul className="space-y-3">
                {column.links.map((link, i) => (
                  <motion.li 
                    key={i}
                    variants={itemVariants}
                    whileHover={{ x: 5 }}
                    transition={{ duration: 0.2 }}
                  >
                    <a 
                      href={link.href}
                      className="text-white/70 hover:text-primary-light transition-colors duration-300"
                    >
                      {link.name}
                    </a>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
      
      {/* Bottom Bar */}
      <div className="border-t border-white/10">
        <div className="container-padding py-6 max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="text-white/50 text-sm text-center md:text-left">
            &copy; {currentYear} WebCoderz. All rights reserved.
          </p>
          <div className="flex space-x-6 mt-4 md:mt-0">
            <a href="#terms" className="text-white/50 hover:text-white text-sm transition-colors duration-300">
              Terms of Service
            </a>
            <a href="#privacy" className="text-white/50 hover:text-white text-sm transition-colors duration-300">
              Privacy Policy
            </a>
            <a href="#cookies" className="text-white/50 hover:text-white text-sm transition-colors duration-300">
              Cookie Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 