import React, { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './App.css';

// Components import
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Loader from './components/Loader';
import Cursor from './components/Cursor';
import ScrollManager from './components/ScrollManager';

function App() {
  const [loading, setLoading] = useState(true);
  const [scrollY, setScrollY] = useState(0);
  const appRef = useRef(null);

  useEffect(() => {
    // Simulating loading time
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App" ref={appRef}>
      <Cursor />
      
      <AnimatePresence>
        {loading ? (
          <motion.div
            key="loader"
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Loader />
          </motion.div>
        ) : (
          <ScrollManager>
            <motion.div
              key="content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="content"
            >
              <Navbar scrollY={scrollY} />
              
              <main>
                <section id="hero" className="section">
                  <Hero scrollY={scrollY} />
                </section>
                
                <section id="about" className="section">
                  <About />
                </section>
                
                <section id="services" className="section">
                  <Services />
                </section>
                
                <section id="portfolio" className="section">
                  <Portfolio />
                </section>
                
                <section id="contact" className="section">
                  <Contact />
                </section>
              </main>
              
              <Footer />
            </motion.div>
          </ScrollManager>
        )}
      </AnimatePresence>
    </div>
  );
}

export default App;
