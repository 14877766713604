import React, { useEffect, useRef } from 'react';
import Lenis from 'lenis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

// ScrollManager handles smooth scrolling and GSAP ScrollTrigger integration
const ScrollManager = ({ children }) => {
  const lenisRef = useRef(null);

  // Initialize smooth scrolling
  useEffect(() => {
    // Initialize Lenis for smooth scrolling with optimized settings
    lenisRef.current = new Lenis({
      duration: 1.0, // Reduced from 1.2 for better responsiveness
      easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)), // Improved easing function
      direction: 'vertical',
      gestureDirection: 'vertical',
      smooth: true,
      smoothTouch: true, // Enable smooth scrolling on touch devices
      touchMultiplier: 1.5, // Reduced from 2 for better control
      infinite: false,
      lerp: 0.1, // Add lerp for smoother interpolation
      wheelMultiplier: 0.8, // Slightly reduce wheel speed for better control
    });

    // Connect Lenis to GSAP's ScrollTrigger
    lenisRef.current.on('scroll', ScrollTrigger.update);

    // More efficient update function
    const raf = (time) => {
      if (lenisRef.current) {
        lenisRef.current.raf(time * 1000);
      }
      requestAnimationFrame(raf);
    };
    
    requestAnimationFrame(raf);

    // Update ScrollTrigger and reset position
    ScrollTrigger.refresh(true);
    window.scrollTo(0, 0);

    // Cleanup function
    return () => {
      if (lenisRef.current) {
        lenisRef.current.destroy();
      }
    };
  }, []);

  // Expose scrollTo method
  useEffect(() => {
    window.scrollTo = (options) => {
      if (lenisRef.current) {
        lenisRef.current.scrollTo(options, {
          offset: 0,
          immediate: false,
          duration: 1.2,
          easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
        });
      }
    };
  }, []);

  return <>{children}</>;
};

export default ScrollManager; 