import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });
  
  const controls = useAnimation();
  
  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  
  const stats = [
    { value: '10+', label: 'Years Experience' },
    { value: '150+', label: 'Projects Completed' },
    { value: '95%', label: 'Client Satisfaction' },
    { value: '24/7', label: 'Support' }
  ];

  const contentVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut'
      }
    }
  };
  
  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
        delay: 0.2
      }
    }
  };
  
  const statsContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.6
      }
    }
  };
  
  const statsItemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut'
      }
    }
  };
  
  const founderItemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut'
      }
    }
  };

  const founders = [
    {
      name: "Prianka",
      role: "Founder",
      description: "Visionary entrepreneur with a passion for creating digital solutions that make a difference. Leads the company's strategic direction and business development initiatives.",
      image: "/images/priyanka.jpeg" // Updated to use the uploaded image
    },
    {
      name: "Shiva",
      role: "Co-founder & Tech Lead",
      description: "Technical mastermind behind WebCoderz with expertise in multiple programming languages and frameworks. Oversees all technical aspects and ensures delivery of high-quality solutions.",
      image: "/images/shiva.jpeg" // Updated to use the uploaded image
    }
  ];

  return (
    <div className="container-padding py-20 relative" ref={ref}>
      <div className="grid-bg"></div>
      
      {/* Decorative elements */}
      <motion.div
        className="absolute top-20 right-10 w-64 h-64 rounded-full bg-primary/10 blur-3xl"
        initial={{ opacity: 0 }}
        animate={controls}
        variants={{
          visible: {
            opacity: 0.6,
            transition: { duration: 1 }
          }
        }}
      />
      
      <motion.div
        className="absolute bottom-20 left-10 w-80 h-80 rounded-full bg-secondary/10 blur-3xl"
        initial={{ opacity: 0 }}
        animate={controls}
        variants={{
          visible: {
            opacity: 0.6,
            transition: { duration: 1, delay: 0.3 }
          }
        }}
      />
      
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <motion.h2 
            className="section-title"
            initial={{ opacity: 0, y: -20 }}
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
            }}
          >
            About WebCoderz
          </motion.h2>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          {/* About content */}
          <motion.div
            initial="hidden"
            animate={controls}
            variants={contentVariants}
          >
            <h3 className="text-3xl font-bold mb-6 text-gradient">
              Transforming Ideas into Digital Reality
            </h3>
            
            <div className="space-y-4 text-white/80">
              <p>
                WebCoderz is a premier tech startup founded by Prianka and Shiva, a dynamic duo passionate about delivering exceptional digital experiences.
              </p>
              <p>
                Since our inception, we have been at the forefront of technological innovation, helping businesses of all sizes transform their digital presence with cutting-edge solutions.
              </p>
              <p>
                Our approach combines technical excellence with creative vision, ensuring each project not only meets but exceeds client expectations. We pride ourselves on building long-term partnerships with our clients, understanding their unique challenges, and delivering solutions that drive tangible results.
              </p>
            </div>
            
            <div className="mt-8">
              <motion.button
                className="btn-primary"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Learn More About Us
              </motion.button>
            </div>
          </motion.div>
          
          {/* 3D Image block */}
          <motion.div
            className="perspective-container"
            initial="hidden"
            animate={controls}
            variants={imageVariants}
          >
            <div className="relative w-full h-80 lg:h-96">
              {/* 3D layered elements */}
              <motion.div 
                className="absolute inset-0 border-2 border-primary-light rounded-lg transform rotate-6 z-10"
                animate={{ 
                  rotateY: [0, 10, 0], 
                  rotateX: [0, -5, 0] 
                }}
                transition={{ 
                  duration: 6, 
                  ease: "easeInOut", 
                  repeat: Infinity 
                }}
              />
              
              <motion.div 
                className="absolute inset-0 bg-dark-light rounded-lg transform rotate-3 z-20"
                animate={{ 
                  rotateY: [0, -8, 0], 
                  rotateX: [0, 4, 0] 
                }}
                transition={{ 
                  duration: 8, 
                  ease: "easeInOut", 
                  repeat: Infinity,
                  delay: 0.5
                }}
              >
                <div className="absolute inset-0 p-6 flex items-center justify-center">
                  <div className="glass w-full h-full rounded-lg flex flex-col items-center justify-center text-center p-6">
                    <div className="text-4xl mb-4">👨‍💻</div>
                    <h4 className="text-xl font-bold text-gradient mb-2">Our Mission</h4>
                    <p className="text-white/80">To empower businesses through innovative technology solutions that drive growth and success in the digital world</p>
                  </div>
                </div>
              </motion.div>
              
              <motion.div 
                className="absolute -bottom-6 -right-6 w-2/3 h-2/3 border-2 border-secondary rounded-lg transform -rotate-6 z-0"
                animate={{ 
                  rotateY: [0, 15, 0], 
                  rotateX: [0, -8, 0] 
                }}
                transition={{ 
                  duration: 7, 
                  ease: "easeInOut", 
                  repeat: Infinity,
                  delay: 1
                }}
              />
            </div>
          </motion.div>
        </div>
        
        {/* Stats section */}
        <motion.div
          className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-20"
          initial="hidden"
          animate={controls}
          variants={statsContainerVariants}
        >
          {stats.map((stat, index) => (
            <motion.div
              key={index}
              className="glass p-6 rounded-xl text-center"
              variants={statsItemVariants}
              whileHover={{ 
                scale: 1.05,
                boxShadow: '0 0 20px rgba(109, 40, 217, 0.4)'
              }}
            >
              <span className="block text-4xl font-bold text-gradient mb-2">{stat.value}</span>
              <span className="text-white/70">{stat.label}</span>
            </motion.div>
          ))}
        </motion.div>
        
        {/* Founders section */}
        <div className="mt-24">
          <motion.h3 
            className="text-2xl md:text-3xl font-bold mb-10 text-center bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent"
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.8 } }
            }}
          >
            Meet Our Founders
          </motion.h3>
          
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12"
            initial="hidden"
            animate={controls}
            variants={{
              visible: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.3,
                  delayChildren: 1
                }
              }
            }}
          >
            {founders.map((founder, index) => (
              <motion.div
                key={index}
                className="bg-gray-900/50 backdrop-blur-sm rounded-2xl overflow-hidden border border-white/5 hover-card"
                variants={founderItemVariants}
              >
                <div className="flex flex-col md:flex-row">
                  <div className="w-full md:w-2/5 h-64 md:h-auto">
                    <img 
                      src={founder.image} 
                      alt={founder.name} 
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="w-full md:w-3/5 p-6 md:p-8">
                    <h4 className="text-xl font-bold text-white mb-1">{founder.name}</h4>
                    <p className="text-sm text-secondary mb-4">{founder.role}</p>
                    <div className="w-12 h-1 bg-gradient-to-r from-primary to-secondary rounded-full mb-4"></div>
                    <p className="text-gray-400">{founder.description}</p>
                    
                    <motion.div 
                      className="mt-6 flex space-x-3"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ delay: 0.5 + index * 0.2 }}
                    >
                      <a href="#" className="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center text-gray-400 hover:text-white hover:bg-primary/20 transition-colors duration-200">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                        </svg>
                      </a>
                      <a href="#" className="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center text-gray-400 hover:text-white hover:bg-primary/20 transition-colors duration-200">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                        </svg>
                      </a>
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default About; 